import { render, staticRenderFns } from "./infiniteGrid.vue?vue&type=template&id=245c428a&scoped=true&"
import script from "./infiniteGrid.vue?vue&type=script&lang=js&"
export * from "./infiniteGrid.vue?vue&type=script&lang=js&"
import style0 from "./infiniteGrid.vue?vue&type=style&index=0&id=245c428a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245c428a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/danyelsanches/Documents/projetos/educatena/educatena-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('245c428a')) {
      api.createRecord('245c428a', component.options)
    } else {
      api.reload('245c428a', component.options)
    }
    module.hot.accept("./infiniteGrid.vue?vue&type=template&id=245c428a&scoped=true&", function () {
      api.rerender('245c428a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/infiniteGrid.vue"
export default component.exports